<template>
  <v-card>
    <v-btn
      style="z-index: 1; top: 1rem;"
      color="gray"
      elevation="0"
      fab
      small
      absolute
      right
      @click="cancel"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-img
      src="/images/farewell.jpg"
      max-width="100%"
      max-height="160px"
    />
    <v-card-title
      class="py-0"
      style="font-size: 1rem; font-weight: 600;"
    >
      {{ $t('changelogs.250331.title') }}
    </v-card-title>
    <v-card-text
      class="py-0"
      style="white-space: pre-line; color: black;"
    >
      <div v-html="parsedMarkdown" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { marked } from 'marked'
import text_ja from '!!raw-loader!@/assets/closing/ja.md'
import text_en from '!!raw-loader!@/assets/closing/en.md'
import text_cn from '!!raw-loader!@/assets/closing/cn.md'


export default {
  name: 'closing-dialog',
  components: {
  },
  inject: ['confirm', 'cancel'],
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState(['lang']),
    ...mapFields(['changelogDialog', 'dailyTaskDialog']),
    parsedMarkdown () {
      switch (this.lang) {
        case 'en':
          return marked(text_en)
        case 'cn':
          return marked(text_cn)
        default:
          return marked(text_ja)
      }
    },
  },
  methods: {
    onBtnClick () {
      this.changelogDialog = false
      this.$router.push({ name: 'travel' })
    },
  },
}
</script>
