import assets_type from './assets_type.json'
import model_configs from './model_configs.json'
// * Put specific constants to specific util files
// * only put general constants here.

// - Environment Variables
// https://cli.vuejs.org/guide/mode-and-env.html

// DEV_MODE == !PROD_MODE ?
export const DEV_MODE = process.env.VUE_APP_ENV === 'development'
export const STAG_MODE = process.env.VUE_APP_ENV === 'staging'
export const PROD_MODE = process.env.VUE_APP_ENV === 'production'

export const APP_TYPE = {
  // Just for showing possible values
  MEMES_DEV: false,
  CRYPKO_ASSETS: false,
}
APP_TYPE[process.env.VUE_APP_TYPE] = true


export const MODELS = {
  HIBISCUS: 'hibiscus',
  GERBERA: 'gerbera',
  GERBERA_M: 'gerbera-m',
  FREESIA: 'freesia',
  FREESIA_M: 'freesia-m',
  ERICA: 'erica',
  CANVAS: 'canvas-v1',
}

export const MODEL_ORDER = [
  MODELS.HIBISCUS,
  MODELS.FREESIA,
  MODELS.FREESIA_M,
  MODELS.GERBERA,
  MODELS.GERBERA_M,
  MODELS.ERICA,
  MODELS.CANVAS,
]


export function getModelConfig (feature = '') {
  if (!feature) {
    return model_configs
  }

  const modelConfigs = {}

  for (const [model, config] of Object.entries(model_configs)) {
    if (config.feature && config.feature[feature]) {
      modelConfigs[model] = {
        paid: config.paid,
        ...config.feature[feature],
      }
    }
  }

  return modelConfigs
}

export const MODEL_CONFIGS = model_configs
export const GEN_MODEL_CONFIG = getModelConfig('gen')
export const FUSE_MODEL_CONFIG = getModelConfig('fuse')
export const ATTRS_MODEL_CONFIG = getModelConfig('attrs')
export const VAR_MODEL_CONFIG = getModelConfig('variation')


export const MODEL_COLORS = {
  [MODELS.GERBERA]: {
    primary: '#38bdf8',
    bg: '#e0f2fe',
    bg2: '#bae6fd',
  },
  [MODELS.FREESIA]: {
    primary: '#fb923c',
    bg: '#fff7ed',
    bg2: '#fed7aa',
  },
  [MODELS.ERICA]: {
    primary: '#84cc16',
    bg: '#f7fee7',
    bg2: '#d9f99d',
  },
  [MODELS.HIBISCUS]: {
    primary: '#f27677',
    bg: '#fef1f1',
    bg2: '#fef1f1',
  },
}

MODEL_COLORS[MODELS.GERBERA_M] = MODEL_COLORS[MODELS.GERBERA]
MODEL_COLORS[MODELS.FREESIA_M] = MODEL_COLORS[MODELS.FREESIA]
MODEL_COLORS[MODELS.CANVAS] = MODEL_COLORS[MODELS.ERICA]

export const ASSET_TYPES = assets_type

export const IMG_SIZES = (() => {
  const entries = Object.entries(assets_type)
    .filter(([, v]) => v.size)
    .map(([k, v]) => [k, v.size])
  return Object.fromEntries(entries)
})()

export const ASCENDING_DISPLAY_SIZES = [
  'MD_W',
  'LG_W',
  'XL_W',
]
export const DESCENDING_IMG_SIZES = ASCENDING_DISPLAY_SIZES.slice().reverse()

export const DEFAULT_CARD_SIZE = 160

export const MEMES_SERVER = 'http://crypko-assets-api-dev.preferred.tech:39500'

export const CRYPKO_TYPE = {
  GEN: 'GEN',
  FUSED: 'FUSED',
  ATTRS: 'ATTRS',
  CANVAS: 'CANVAS',
  VARIATION: 'VAR',
}

if (DEV_MODE) {
  CRYPKO_TYPE.KEYPOINT = 'KEYPOINT'
  CRYPKO_TYPE.PROJ = 'PROJ'
}

export const CRYPKO_FILTER_PARAMS = {
  MODEL: {
    key: 'model',
    value: Object.values(MODELS),
  },
  TYPE: {
    key: 'type',
    value: Object.values(CRYPKO_TYPE),
  },
  PRIVACY: {
    key: 'privacy',
    value: ['public', 'private'],
  },
  CROSSFUSE: {
    key: 'crossfuse',
    value: [true, false],
  },
}

// - frontend specific constants

export const EDIT_MODE = {
  SELECTION: 'selection',
  RECTANGLE_CUTOUT: 'rectangleCutout',
  FREE_CUTOUT: 'freeCutout',
  DRAWING: 'drawing',
  ERASER: 'eraser',
  COLOR_BUCKET: 'colorBucket',
}

export const CRYPKO_TEMPLATE_TYPE = {
  WHOLE_WIDTH: 'wholeWidth',
  PICTORIAL_BOOK: 'pictorialBook',
  UPRIGHT_SIGNAGE: 'uprightSignage',  // for e-mote in Comike
}


// narrowest window size for workbench to function normally
export const MIN_WINDOW_WIDTH = 900

export const FUSE_ORIGIN_NUM = 2  // can only fuse two crypkos into a new one

export const ITEMS_PER_PAGE = 20

// Number of slots for once Gacha
export const SLOTS_NUM = 10

export const MARKING_PREVIEW_NUM = 4

// The same as ordering_fields in CrypkoViewSet
// const ORDERING_FIELDS = ['owner', 'model',
// 'type', 'name', 'tags', 'profile', 'created']

export const ORDERING_TYPES = {
  crypko: [
    '-created',
    'created',
    'name',
    '-name',
    '-likes',
    'default',
  ],
  user: [
    'username',
    '-username',
    'date_joined',
    '-date_joined',
  ],
  list: [
    '-created',
    'created',
    'name',
    '-name',
  ],
  list_crypko: [
    '-added',
    'added',
    '-created',
    'created',
    'name',
    '-name',
  ],
  album_gallery: [
    'default',
    'claimed_at',
    'likes',
  ],
  album: [
    'claimed_at',
    'likes',
  ],
}

export const DEFAULT_ORDERING_TYPE = {
  crypko: '-created',
  user: 'username',
  list: '-created',
  list_crypko: '-added',
}

export const NAV_TYPE = {
  GALLERY: 'GALLERY',
  CREATE: 'CREATE',
  LIBRARY: 'LIBRARY',
  PLAY: 'PLAY',
}

export const NAV_BTN = {
  [NAV_TYPE.GALLERY]: {
    to: {
      name: 'gallery',
      params: {
        type: 'crypkos',
      },
    },
    icon: 'mdi-view-grid',
  },
  [NAV_TYPE.CREATE]: {
    to: {
      name: 'create',
    },
    icon: 'mdi-face',
  },
  [NAV_TYPE.LIBRARY]: {
    to: {
      name: 'user-crypko',
    },
    icon: 'mdi-image-multiple',
  },
  [NAV_TYPE.PLAY]: {
    to: {
      name: 'play',
    },
    icon: 'mdi-shimmer',
  },
}

export const GALLERY_VIEW_NAME = {
  ALBUM: 'album',
  RANK: 'rank',
  TREND: 'trend',
  CHATTABLE: 'chattable',
  CROSSFUSE: 'crossfuse',
  FOLLOWING: 'following',
  HIBISCUS: 'hibiscus',
  FREESIA: 'freesia',
  FREESIA_M: 'freesia_m',
  GERBERA: 'gerbera',
  ERICA: 'erica',
  CANVAS: 'canvas',
  SEARCH: 'search',
}

export const GALLERY_ROUTE_NAME = {
  TREND: 'gallery-trend',
  CHATTABLE: 'gallery-chattable',
  CROSSFUSE: 'gallery-crossfuse',
  FOLLOWING: 'gallery-following',
  FREESIA: 'gallery-freesia',
  FREESIA_M: 'gallery-freesia_m',
  GERBERA: 'gallery-gerbera',
  HIBISCUS: 'gallery-hibiscus',
  ERICA: 'gallery-erica',
  CANVAS: 'gallery-canvas',
  SEARCH: 'gallery-search',
}

export const CREATE_NAV_TYPE = {
  GENERATE: 'GENERATE',
  FUSE: 'FUSE',
  ATTRS: 'ATTRS',
  VARIATION: 'VARIATION',
  CANVAS: 'CANVAS',
  INTERPOLATE: 'INTERPOLATE',
}

if (DEV_MODE) {
  CREATE_NAV_TYPE.KEYPOINT = 'KEYPOINT'
}

export const CREATE_ROUTE_NAME = {
  CREATE: 'create',
  GENERATE: 'create-generate',
  FUSE: 'create-fuse',
  ATTRS: 'create-attributes',
  VARIATION: 'create-variation',
  CANVAS: 'create-canvas',
  INTERPOLATE: 'create-interpolate',
}

if (DEV_MODE) {
  CREATE_ROUTE_NAME.KEYPOINT = 'create-keypoint'
  CREATE_ROUTE_NAME.INTEPOLATE = 'create-interpolate'
}

export const CREATE_NAV_BTN = {
  [CREATE_NAV_TYPE.GENERATE]: {
    to: {
      name: CREATE_ROUTE_NAME.GENERATE,
    },
    rightDrawer: false,
    toolbarShadow: false,
    color: '#B16A5E',
    isAvailable (user) {
      return !!user
    },
    requiresCrypko: false,
  },
  [CREATE_NAV_TYPE.FUSE]: {
    to: {
      name: CREATE_ROUTE_NAME.FUSE,
    },
    rightDrawer: false,
    toolbarShadow: false,
    color: '#D29554',
    isAvailable (user) {
      return !!user
    },
    requiresCrypko: true,
  },
  [CREATE_NAV_TYPE.ATTRS]: {
    to: {
      name: CREATE_ROUTE_NAME.ATTRS,
    },
    rightDrawer: true,
    toolbarShadow: false,
    color: '#DBC08C',
    isAvailable (user) {
      return !!user && !user.isFreemium() && !user.isStarter()
    },
    requiresCrypko: true,
  },
  [CREATE_NAV_TYPE.VARIATION]: {
    to: {
      name: CREATE_ROUTE_NAME.VARIATION,
    },
    rightDrawer: false,
    toolbarShadow: false,
    color: '#888081',
    isAvailable (user) {
      return !!user && !user.isFreemium() && !user.isStarter()
    },
    requiresCrypko: true,
  },
  [CREATE_NAV_TYPE.CANVAS]: {
    to: {
      name: CREATE_ROUTE_NAME.CANVAS,
    },
    rightDrawer: true,
    toolbarShadow: false,
    color: '#96B8A2',
    beta: true,
    isAvailable (user) {
      return !!user && !user.isFreemium()
    },
    requiresCrypko: true,
  },
  [CREATE_NAV_TYPE.INTERPOLATE]: {
    to: {
      name: CREATE_ROUTE_NAME.INTERPOLATE,
    },
    rightDrawer: false,
    color: '#656E83',
    isAvailable (user) {
      return !!user
    },
    requiresCrypko: true,
  },
}

if (DEV_MODE) {
  CREATE_NAV_BTN[CREATE_NAV_TYPE.KEYPOINT] = {
    to: {
      name: CREATE_ROUTE_NAME.KEYPOINT,
    },
    rightDrawer: false,
    color: '#888081',
    alpha: true,
    isAvailable (user) {
      return user && !user.isFreemium() && !user.isStarter()
    },
    requiresCrypko: true,
  }
}

if (APP_TYPE.MEMES_DEV) {
  CREATE_NAV_TYPE.MARKING = 'MARKING'
  CREATE_NAV_BTN[CREATE_NAV_TYPE.MARKING] = {
    to: {
      name: 'create-marking',
    },
    rightDrawer: false,
  }
}


export const MARKET_NAV_TYPE = {
  PROVIDER: 'PROVIDER',
}

export const MARKET_NAV_BTN = {
  [MARKET_NAV_TYPE.PROVIDER]: {
    to: {
      name: 'provider-market',
    },
  },
}

export const USER_NAV_TYPE = {
  HOME: 'HOME',
  CRYPKO: 'CRYPKO',
  FAVED: 'FAVED',
  LIST: 'LIST',
  ALBUM: 'ALBUM',
}

export const USER_NAV_BTN = {
  [USER_NAV_TYPE.HOME]: {
    to: {
      name: 'user-home',
    },
  },
  [USER_NAV_TYPE.CRYPKO]: {
    to: {
      name: 'user-crypko',
    },
  },
  [USER_NAV_TYPE.ALBUM]: {
    to: {
      name: 'user-album',
    },
  },
  [USER_NAV_TYPE.FAVED]: {
    to: {
      name: 'user-faved',
    },
  },
  [USER_NAV_TYPE.LIST]: {
    to: {
      name: 'user-list',
    },
  },
}

export const FOLLOW_NAV_TYPE = {
  FOLLOWINGS: 'FOLLOWINGS',
  FOLLOWERS: 'FOLLOWERS',
}

export const FOLLOW_NAV_BTN = {
  [FOLLOW_NAV_TYPE.FOLLOWINGS]: {
    to: {
      name: 'followings-user',
    },
  },
  [FOLLOW_NAV_TYPE.FOLLOWERS]: {
    to: {
      name: 'followers-user',
    },
  },
}

export const CRYPKO_PICKER_MODE = {
  OWNED: 'owned',
  CROSSFUSE: 'crossfuse',
  LIST: 'list',
}

import i18n from '../i18n'

export const TEXT_FIELD_RULES = {
  EMAIL: [
    v => !!v || 'E-mail is required',
    v => /.+@.+\..+/.test(v) || i18n.t('auth.invalid-email'),
  ],
  USERNAME: [
    v => !!v || 'Name is required',
    v => v && v.length >= 4 || 'minimum 4 characters',
    v => v && v.length <= 16 || 'maximum 16 characters',
  ],
  PASSWORD: [
    v => !!v || 'Password is required',
    v => /^(?=.*\d)(?=.*[a-zA-Z]).{8,128}$/.test(v) ||
      '8~128 characters, contains at least a letter and a number.',
  ],
}

export const LAYER_WEIGHT_TYPES = {
  POSE: 'pose',
  DETAILS: 'details',
  COLORS: 'colors',
}

export const LAYER_WEIGHT_TEMPLATES = {
  [LAYER_WEIGHT_TYPES.POSE]: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
  [LAYER_WEIGHT_TYPES.DETAILS]: [0, 0, 1, 1, 1, 0, 0, 0, 0, 0],
  [LAYER_WEIGHT_TYPES.COLORS]: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
}

export let VARIATION_MODES = [
  {
    param_type: 'layer',
    mode: 'pose',
    icon: 'mdi-human-handsdown',
  },
  {
    param_type: 'mask_hand',
    mode: 'hand',
    icon: 'mdi-hand-back-left',
  },
  {
    param_type: 'mask_clothes',
    mode: 'clothes',
    icon: 'mdi-hanger',
  },
]

if (DEV_MODE) {
  VARIATION_MODES = VARIATION_MODES.concat([
    {
      param_type: 'mask_hair',
      mode: 'hair',
      icon: 'mdi-hair-dryer',
    },
    {
      param_type: 'mask_face',
      mode: 'face',
      icon: 'mdi-face',
    },
  ])
}

// - Emote
// at most 6 diffTimeline Slots
export const MAX_DIFF_TIMELINE = 6

export const CACHE_TIMEOUT = 300

export const OSS_CONFIG = {
  region: 'oss-ap-northeast-1',
  bucket: process.env.VUE_APP_OSS_BUCKET,
}

export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY

export const GOOGLE_FORM_URL = 'https://forms.gle/iQwRAmhSrvNFKVq2A'

export const DEFAULT_AVATAR_SRC = '/images/DefaultProfile.jpg'

export const HELP_IMAGE_MAX_WIDTH = 600

export const STAMINA_POLLING_INTERVAL = 30 * 1000
export const NOTIFICATION_POLLING_INTERVAL = 15 * 1000

export const SUBSCRIPTION_PLANS = {
  FREEMIUM: 'Freemium',
  STARTER: 'Starter',
  STANDARD: 'Standard',
  PREMIUM: 'Premium',
}

let STRIPE_PUBLISHABLE_KEY = null
let PRICES = null
if (PROD_MODE || STAG_MODE) {
  // Live Mode
  STRIPE_PUBLISHABLE_KEY = 'pk_live_51JQRc2B2rHFyaQY2iaDuS0fL03Z9YYlte46B8b9z6KYVUA88WVwBwnkf5spo4Mte7QUIrh9W596ppz4iZL0zDBPg00RHvQAjbK'
  PRICES = {
    SUBSCRIPTION: {
      STARTER: {
        MONTHLY: 'price_1N0zN2B2rHFyaQY2pkDk3dNA',
        ONEMONTH: 'price_1N0zN2B2rHFyaQY2wGzkaoJX',
      },
      STANDARD: {
        MONTHLY: 'price_1KsG3HB2rHFyaQY2Dkloo8v5',
        ONEMONTH: 'price_1LQ3rJB2rHFyaQY2wt0tHqwz',
      },
      PREMIUM: {
        MONTHLY: 'price_1Ll3F0B2rHFyaQY29TtKpaqM',
        ONEMONTH: 'price_1Ll3FuB2rHFyaQY29elWobfV',
      },
    },
    SLOT: {
      10: 'price_1L2UEVB2rHFyaQY23gbDDkyr',
      110: 'price_1L2UEVB2rHFyaQY2JPBoECLF',
    },
  }
} else {
  // Test Mode
  STRIPE_PUBLISHABLE_KEY = 'pk_test_51JQRc2B2rHFyaQY2QHCDVtrtEQMw5epsTmXdJ0BBm7zIzpe4kiUKUnc0ZEvV0amxrHNAdhRYwh2bXCdj7rigDcIi00fTo5LXPh'
  PRICES = {
    SUBSCRIPTION: {
      STARTER: {
        MONTHLY: 'price_1Mr8xpB2rHFyaQY2fJmgZMWJ',
        ONEMONTH: 'price_1Mr8zBB2rHFyaQY2hTnY1T2Q',
      },
      STANDARD: {
        MONTHLY: 'price_1Kg3QlB2rHFyaQY22lLoE1yv',
        ONEMONTH: 'price_1LLfzSB2rHFyaQY2l7Rerz4r',
      },
      PREMIUM: {
        MONTHLY: 'price_1LkO8yB2rHFyaQY2uLwQX5Dv',
        ONEMONTH: 'price_1LkO9FB2rHFyaQY294I6CTun',
      },
    },
    SLOT: {
      10: 'price_1L2VLZB2rHFyaQY2zuZLh2R4',
      110: 'price_1L2VLnB2rHFyaQY2eoYbpt0b',
    },
  }
}
export { STRIPE_PUBLISHABLE_KEY, PRICES }

export const DISCORD_LINK = 'https://discord.gg/Hmg6bxw2Uc'
export const TWITTER_LINK = 'https://twitter.com/Crypko'

export const SENTRY_ERROR_MSG_BLACKLIST = [
  /Request failed with status code 4/i,
  /Non-Error promise rejection captured with value: Timeout/i,
  /Navigating to current location/i,
  /Avoided redundant navigation to current location/i,
  /window.bannerNight is not a function/i,
  /window.Swiper is not a constructor/i,
  /Network Error/i,
  /'_t'/i,
  /Non-Error promise rejection captured with value: Timeout/i,
  /Request aborted/i,
  /Loading chunk/i,
]

// Please also change numbers in ConfirmTermsDialog.vue
export const LATEST_TERMS_VERSION = '24032100'

export const LATEST_CHANGELOG_VERSION = '250331'

export const MAX_PROFILE_LEN = 300

export const MAX_CHAT_INPUT_LENGTH = 100

export const TRAVEL_SPEEDUP_COST = 10
export const TRAVEL_START_COST = 20

export const TRAVEL_WORLDS = {
  // HALLOWEEN: 'Halloween',
  // CHRISTMAS: 'Christmas',
  SAKURA: 'Sakura',
  ARCADIA: 'Arcadia',
  ORBIS: 'Orbis',
  CYBRITUS: 'Cybritus',
}

export const TRAVEL_WORLD_CONFIG = {
  // [TRAVEL_WORLDS.HALLOWEEN]: {
  //   paid: false,
  //   event: true,
  // },
  // [TRAVEL_WORLDS.CHRISTMAS]: {
  //   paid: true,
  //   event: true,
  // },
  [TRAVEL_WORLDS.SAKURA]: {
    paid: false,
    event: true,
  },
  [TRAVEL_WORLDS.ARCADIA]: {
    paid: false,
  },
  [TRAVEL_WORLDS.ORBIS]: {
    paid: false,
  },
  [TRAVEL_WORLDS.CYBRITUS]: {
    paid: true,
  },
}