export default {
  general: {
    crypko: 'Crypko',
    model: '生成模型',
    slot: '保存数',
    stamina: 'CP',
    coin: '星币',
    guideline: '使用指南',
    terms: '服务条款',
    'privacy-policy': '隐私政策',
    'crypko-guideline': 'Crypko使用指南',
    'commercial-license': '商用许可',
    dev: 'dev',
    support: {
      email: 'support@info.crypko.ai',
      'faq-link': 'https://crypko.zendesk.com/hc/zh-cn/categories/10851919592601-FAQ',
      'contact-link': 'https://crypko.zendesk.com/hc/zh-cn/requests/new',
    },
    label: {
      generate: '生成',
      fuse: '融合',
      settings: '设置',
      set: '设置',
      functionality: '功能',
      save: '保存',
      delete: '删除',
      update: '更新',
      apply: '应用',
      select: '选择',
      pleaseSelect: '请选择',
      edit: '编辑',
      yes: '确认',
      no: '否定',
      reset: '重置',
      close: '关闭',
      submit: '提交',
      download: '下载',
      cancel: '取消',
      confirm: '确认',
      share: '分享',
      report: '举报',
      follow: '关注',
      following: '已关注',
      all: '全部',
      error: '错误',
      language: '语言',
      load: '加载',
      done: '完成',
      more: '更多',
      refresh: '更新',
      none: '空',
      purchase: '购买',
      retry: '重试',
      full: '全部',
      agree: '同意',
      send: '发送',
      resend: '再次发送',
      'delete-unwanted': '整理现有Crypko',
      account: '账号',
      comment: '评论',
      'slots-remain': '剩余保存数',
      'contact-by-email': '通过Email咨询',
      'email-address': 'Email',
      times: '次数',
      fav: '点赞',
      notifications: '通知',
      upgrade: '升级',
      support: '支持',
      result: '结果',
      owner: '所有者',
      'try-now': '立即尝试',
      'acquire-now': '前去获取',
      'view-other-crypkos': '查看其他Crypko',
      'try': '去完成',
      'claim': '领取奖励',
      'claim-all': '全部领取',
      'claimed': '已领取',
      exchange: '兑换',
      details: '详情',
      reward: '奖励',
      feedback: '反馈',
      'continue-anyway': 'Still continue',
      characters: '角色',
      recommended: '推荐',
      others: '其他',
      'go-gen': '去生成',
      feature: '功能',
      event: '活动',
    },
    settings: {
      AttrsCPConfirm: '属性中显示CP对话框',
      NSFWConfirm: '显示NSFW图片',
    },
    msg: {
      succeeded: '成功。',
      failed: '失败。',
      retry: '请重试。',
      saveConfirm: '要保存吗？',
      saveSeveralConfirm: '要保存{number}个吗？',
      saved: '成功保存',
      unsaved: '有尚未保存的操作',
      overwrite: '需要覆盖吗？',
      delete: '需要删除吗？',
      fetching: '正在读取',
      placeholder: '请输入',
      empty: '空',
      error: '发生错误。',
      enableAfterSubscribe: '付费订阅尚可使用。',
      thankyou: '谢谢！',
      waiting: '请稍等。',
      welcome: '欢迎来到Crypko！',
      'cp-insufficient': 'CP不足',
      'coin-insufficient': '星币不足',
      'before-share': '当分享到社交平台是时，请遵守{0}。',
      'cancel-crossfuse': '取消设置为协助融合吗？',
      'own-crypko': '您持有该 Crypko。',
      'delete-licensed-crypko': '删除购买了商用许可的Crypko将导致您无法继续商用此Crypko。',
      'max-length': '最多{0}个字符。',
      'refresh-in': '将在 {0} 后刷新。',
      'crypko-deleted': 'Crypko已删除',
    },
  },
  models: {
    erica: 'Erica',
    freesia: 'Freesia',
    'freesia-m': 'FreesiaM',
    gerbera: 'Gerbera',
    'gerbera-m': 'GerberaM',
    hibiscus: 'Hibiscus',
    stylegan: 'StyleGAN',
    'edit-v1': 'EditV1',
    'canvas-v1': 'CanvasV1',
    others: 'その他',
    desc: {
      'hibiscus': '上半身',
      'gerbera': '全身',
      'gerbera-m': '全身',
      'freesia': '上半身',
      'freesia-m': '上半身',
      'erica': '脸部',
      'canvas-v1': '脸部',
    },
    'supported-models': '支持的模型',
    'supported-feature': '支持的功能',
    'gen-sample': '生成样例',
    free: '免费模型',
    paid: '付费模型',
  },
  crypko: {
    card: {
      tooltip: {
        chat: '聊天',
        crossfuse: '协助融合',
        nsfw: 'NSFW',
      },
    },
    profile: {
      set_with_error: '可能包含不适宜的内容。',
      save: '保存',
      generate: '生成',
      generate_title: '生成',
      save_confirm_desc: '是否在聊天中使用该简介？',
      save_and_chattable: '保存并开启聊天',
      save_and_unchattable: '保存并禁用聊天',
      start_chat_title: '保存成功',
      start_chat_desc: '已经可以使用Crypko聊天功能',
      start_chat: '开始聊天',
      empty: '该 Crypko 尚未设置简介。',
      edit: '点击为该 Crypko 编辑简介。',
      choose_profile_title: '选择简介',
      choose_profile_desc: '该Crypko的创建者已经更新了他们的简介。你想使用新的简介吗？',
      use_new: '使用新的简介',
      use_old: '使用旧的简介',
      generate_and_chat_title: '创建简介',
      generate_and_chat_desc: '创建简介，从而与 Crypko 聊天。',
      input: '输入简介',
    },
    type: {
      GEN: '生成',
      FUSED: '融合',
      ATTRS: '属性',
      CANVAS: '画布',
      KEYPOINT: '姿势',
      VAR: '派生',
      PROJ: 'Crypko化',
    },
    'relation-map': '关系图',
    crossfuse: '协助融合',
    latest: '最新',
    owned: '所持',
  },
  auth: {
    'signup-signin': '注册/登录',
    signup: '注册账户',
    signin: '登录',
    signout: '注销',
    username: '用户名',
    password: '密码',
    'reset-pwd': '重置密码',
    'change-email': '更换邮箱',
    'input-new-email': '更换',
    'new-email-dialog-title': '更换邮箱',
    'new-email-dialog-desc': '请输入新邮箱',
    'new-email-label': '新邮箱',
    'verify-pwd': '验证密码',
    'verify-pwd-desc': '请输入密码',
    'email-sent-title': '验证邮件已发送',
    'email-sent-desc': '请查看验证邮件，以完成邮箱变更操作。',
    'confirm': '确认',
    'send-email': '发送邮件',
    'send-reset-email': '通过邮件重置',
    'new-pwd': '新密码',
    'confirm-pwd': '密码(确认)',
    'forget-pwd': '忘记密码的场合',
    'pwd-mismatch': '密码错误。',
    'invalid-email': '邮箱错误。',
    'invalid-login': '登录失败，请检查输入的邮箱和密码。邮箱和密码均为大小写敏感。',
    'email-sent': '邮件已发送。下次重试请等待%{second}秒。',
    goCreate: '生成角色',
    goGallery: '访问Gallery',
    goPlayground: '与看板娘聊天',
    goTravel: '去旅行',
    'email-verify-failed': '邮箱认证失败。',
    'please-login': '请登录。',
    'official-twitter': '官方推特',
    'official-discord': '官方Discord',
    'age-confirm': '我已达到{0}中规定的年龄。',
    'age-confirm-terms': '服务条款第三条',
    'agreements': '我同意 {0} 和 {1}。',
    'agree-on-click': '点击【注册】按钮，就代表同意：',
    'agree-on-guideline': '已经阅读并同意{0}。',
    'delete-account': '删除账号',
    'delete-account-confirm': '同意并删除账号',
    'delete-account-title': '确定要删除账号吗？',
    'delete-account-msg': '您的Crypko、CP、保存数会随账号一起被删除。请注意此操作无法复原。',
    'delete-account-subscription-title': '存在有效期中的订阅',
    'delete-account-subscription-msg': '请在删除账号之前取消您的会员订阅。',
    'continue-with-google': '使用Google账号',
    'or': '或者',
    verifyEmail: {
      'title': '验证邮箱',
      'desc': '使用本网站功能需要验证您的邮箱。请按照邮件指示验证您的邮箱。',
      'done-browse': '账号验证成功。',
      'done-login': '账号验证成功。请登录。',
      'email-sent-address': 'Email: %{address}',
    },
  },
  dialog: {
    noSegmentation: {
      title: 'Load Error',
      text: '读取失败。<br/>请刷新页面后重试。',
      actions: {
        true: 'OK',
      },
    },
    nsfw: {
      text: '这张图像可能包含敏感内容。',
      actions: {
        true: '显示',
        false: '不显示',
      },
    },
    'confirm-terms': {
      title: '服务条款变更的通知',
      text: '自{0}年{1}月{2}日，本站服务条款已变更。本站将使用以下变更的服务条款。',
      actions: {
        true: '同意',
        false: '不同意',
      },
    },
    'freemium-fuse': {
      text: '升级您的会员以融合两个您持有的Crypko。',
    },
    'chat-cp': {
      text: '发送一次信息将会消耗3CP。\n*这个对话框只会显示一次。',
    },
    'chat-freemium': {
      title: '聊天功能',
      actions: {
        0: '尝试与看板娘聊天',
      },
    },
    'get-coin': '{delta}星币！总计：{total}。',
    'spend-coin': '花费{delta}星币。剩余：{total}。',
    'coin-exchanged': {
      title: '已兑换',
    },
    'not-enough-coin': {
      title: '星币不足',
      message: '星币不足。查看每日任务赚取星币吧！',
    },
    'remove-album': {
      title: '确认',
      message: '确认从Crypko删除这张旅行相片吗？',
      message2: '确认从Crypko删除这张旅行相片吗？此操作可能无法撤销。',
    },
    'earn-coins': {
      title: '星币的获取方法',
      'daily-task': '完成每日任务。',
      travel: '进行Crypko旅行。',
    },
    'require-crypko': {
      title: 'Requires Crypko',
      message: 'Please generate your Crypko first.',
    },
    'generate-crypko': {
      title: '生成Crypko',
      'save-and-continue': '保存并继续',
      'msg-travel': '开始旅行之前您需要持有至少一个Hibiscus/Freesia/Gerbera模型的Crypko。',
    },
    'survey-no-travel': {
      'title': '免费获得500星币',
      'message': '完成问卷即可获得500金币。在此之前您必须至少玩一次Crypko旅行功能。',
      'action': 'Crypko旅行',
    },
  },
  header: {
    router: {
      page404: '404',
      about: 'About',
      'create-attributes': '属性',
      'create': 'Create',
      'create-canvas': '画布',
      emoteDemo: 'E-mote',
      'create-fuse': '融合',
      'create-generate': '生成',
      'create-keypoint': '姿势',
      'create-marking': '标记',
      'create-variation': '派生',
      'create-interpolate': '变身动画',
      'workbench': '制作',
      'user-home': '主页',
      'user-crypko': 'Crypkos',
      'user-album': '旅行',
      'user-faved': '赞过',
      'notifications': '通知',
      'user-list': '列表',
      'followings-user': '关注',
      'followers-user': '粉丝',
      signin: '登录',
      list: '列表',
      logout: '注销',
      gallery: 'Gallery',
      'signup': '注册',
      'signup-signin': '注册/登录',
      'signup-welcome': '注册',
      'introduction': 'Crypko - AI动漫角色生成',
      help: '帮助',
      settings: '设置',
      'terms': '服务条款',
      'privacy-policy': '隐私政策',
      'guideline': 'Crypko指南',
      'verify-email': '验证邮箱',
      'verify-password': '验证密码',
      'reset-pwd-request': '重设密码',
      'reset-pwd-submit': '重设密码',
      plans: '订阅',
      'gallery-crypko': 'Gallery',
      'gallery-trend': '趋势',
      'gallery-chattable': '聊天',
      'gallery-crossfuse': '协作融合',
      'gallery-following': '关注中',
      'gallery-gerbera': 'Gerbera',
      'gallery-freesia': 'Freesia',
      'gallery-freesia_m': 'FreesiaM',
      'gallery-erica': 'Erica',
      'gallery-search': 'Search',
      'gallery-travel': 'Gallery',
      'crypko-tree': '关系图',
      travel: '旅行',
      play: 'Play',
      survey: '问卷',
    },
    avatar: {
      home: '用户主页',
      notifications: '通知',
      stampCard: '签到卡',
      plans: '会员商城',
      settings: '设置',
      help: '帮助',
      logout: '注销',
      'system-notification': '更新内容',
      'daily-task': '每日任务',
    },
  },
  gallery: {
    views: {
      home: '主页',
      album: '旅行',
      crypkos: 'Crypko',
      chattable: '聊天',
      lists: '列表',
      users: '用户',
      trend: '趋势',
      crossfuse: '协助融合',
      following: '关注',
      hibiscus: 'Hibiscus最新',
      gerbera: 'Gerbera(M)最新',
      freesia: 'Freesia(M)最新',
      erica: 'Erica最新',
      canvas: 'Canvas最新',
      rank: '排行榜',
    },
    filter: {
      label: '过滤',
      options: '选项',
      model: '生成模型',
      type: '类型',
      status: '状态',
    },
    ordering: {
      'title': '排序',
      'created': '最旧',
      '-created': '最新',
      'name': '名字(A→Z)',
      '-name': '名字(Z→A)',
      '-likes': '点赞',
      'default': '推荐',
      'date_joined': '最旧',
      '-date_joined': '最新',
      'username': '名字(A→Z)',
      '-username': '名字(Z→A)',
      'added': '最旧添加',
      '-added': '最新添加',
      'likes': '点赞',
      'claimed_at': '最新',
    },
  },
  user: {
    status: {
      'please-login': '请登录',
      empty: '空',
    },
  },
  privacy: {
    setting: '公开设置',
    public: {
      title: '公开',
      description: '其他用户能够阅览。',
      save: '公开保存',
    },
    private: {
      title: '私密',
      description: '其他用户无法阅览。无法分享。',
      save: '非公开保存',
    },
  },
  avatar: {
    avatar: '头像',
    select: '头像设定',
  },
  stamp: {
    loginBonus: '登录奖励',
    'next-stamp-in': '距下次奖励 {0}',
    preparing: '正在准备中...',
    obtain: '通过每日登录获取',
  },
  detail: {
    profile: {
      title: '资料',
      default: 'Crypko {name} 的介绍',
    },
    hash: {
      title: '哈希值',
    },
    created: {
      title: '生成时间',
    },
    model: {
      title: '生成模型',
      tooltip: 'Crypko生成所用的模型',
    },
    type: {
      title: '类型',
      tooltip: 'Crypko的类型',
    },
    rating: {
      title: '分级',
      empty: '无',
    },
    comment: {
      title: '介绍',
      edit: '编辑',
      empty: '',
    },
    parentCrypko: {
      title: '父Crypko',
      empty: '无',
    },
    childCrypko: {
      title: '子Crypko',
      empty: '无',
    },
    tags: {
      title: '标签',
      empty: '没有标签',
      rate: '出现率',
    },
    name: {
      edit: '编辑名字',
    },
    toolbar: {
      'favorite': '赞',
      'share': '分享',
      'addList': '添加到列表',
      'edit': '编辑',
      'download': '下载',
    },
  },
  edit: {
    composition: '构图',
    layer: '图层',
    style: '风格',
    layers: {
      'background': '背景',
      'backHair': '后发',
      'skin': '皮肤',
      'face': '面部',
      'frontHair': '前发',
      'clothes': '衣服',
      'eyeWhite': '眼白',
      'eyeBlack': '瞳孔',
      'eyelashes': '睫毛',
      'nose': '鼻子',
      'mouth': '嘴',
      'eyebrows': '眉毛',
    },
    settings: {
      mode: {
        drawing: '画笔',
        eraser: '橡皮擦',
        selection: '选择',
        rectangleCutout: '长方形选框',
        freeCutout: '自由选框',
        colorBucket: '油漆桶',
      },
      cursorSize: '画笔尺寸',
      mixRatio: '混合程度',
    },
  },
  footer: {
    'contact': {
      'title': '支持咨询',
      'text-1': '关于Crypko的功能，请参考{0}。',
      'text-2': '其他的问题请联系{0}。',
      'text-3': '※现在Crypko还处于开发阶段，回信需要等待，请理解谢谢。',
      'text-4': '※也可以在官方{0}寻求帮助。',
    },
  },
  help: {
    usage: '使用方法',
    create: {
      generate: {
        desc: '一键进行角色生成',
        1: '1. 选择生成模型并点击生成。',
        2: '2. 选择喜欢的生成结果（可多选）并保存。',
      },
      fuse: {
        desc: '融合两个相同模型的角色，生成新的角色。',
        1: '1. 选择想要融合的两个相同模型的Crypko。',
        0: '* 融合两个自己持有需要加入付费会员',
        2: '2. 选择喜欢的融合结果（可多选）并保存。',
      },
      crossfuse: {
        desc: '协助融合功能是实现您和其他用户的Crypko相融合的功能。协助融合将花费30CP，提供方将获得10CP（每天最多一次）。',
        2: '设置方法',
        3: '您可以在自己的用户页面设置您的协助融合Crypko。',
        4: '融合方法',
        5: '在融合选择对话框中选择其他用户的协助融合Crypko。',
        6: '选择自己的Crypko并融合。',
        7: '在Crypko页面单击协助融合按钮也能使用协助融合。',
      },
      attributes: {
        desc: '调整Crypko的属性',
        1: '1. 选择想要调整属性的Crypko。',
        2: '2. 调整属性滑块（可多选）、点击更新。',
        3: '3. 对结果满意的情况，点击保存。',
      },
      variation: {
        desc: '生成不同的角色变种，如不同的姿势或服装。',
        1: '1. 选择想要派生的Crypko。',
        2: '2. 选择派生模式和强度。',
        3: '3. 点击派生按钮。',
        4: '4. 对结果满意的情况，点击保存。',
      },
      canvas: {
        desc: '在画布上自由编辑角色',
        0: '*每一次编辑的应用不消耗CP。',
        '01': '*保存消耗20CP。',
        '02': '*以上细节在本功能Canvas的β测试结束后可能会发生变化。感谢您的理解。',
        1: '1.选择将作为基础构图的Crypko（仅Erica或CanvasV1模型）。',
        2: '2.使用工具编辑画布。',
        3: '3.（可选）可以为另一个Crypko指定风格参考图像。',
        4: '4.（可选）在一个图层上点击鼠标右键可以调整构图图像和风格参考图像之间的混合比例。',
        5: '5. 点击应用按钮应用编辑，当您喜欢结果时保存。',
      },
      keypoint: {
        0: '*まだ開発中です。',
        1: '1. ポーズを変更したいCrypkoを選択する。',
        2: '2. 参考ポーズのCrypkoを選択する。',
        3: '3. 変更ボタンをクリックする。',
        4: '4. 結果が気に入ったら保存する。',
      },
      interpolate: {
        desc: '制作两个Crypko的变身动画。',
        0: '*变身动画功能制作的动画不支持商用。',
        1: '*变身动画的生成有花费时间的可能性。',
        2: '*以上细节在本功能的β测试结束后可能会发生变化。感谢您的理解。',
        3: '1. 选择想要制作变身动画的两个相同模型的Crypko。（目前仅支持Freesia(M)）',
        4: '2. 点击生成按钮。',
        5: '3. 点击下载按钮以保存生成的动画。',
      },
      travel: {
        desc: '将您的Crypko送到不同的世界旅行的功能。您可以获得旅行相片和星币作为奖励。',
        2: '* 目前旅行是Beta功能，参数规格可能会更改。',
        3: '关于旅行相片',
        4: '1. 每张旅行相片消耗一个Crypko的相册槽。',
        5: '2. 旅行相片与Crypko角色相关联。如果删除Crypko，则其旅行相片也将被删除。',
        6: '3. 旅行相片的隐私设置（公开或非公开）遵循Crypko的隐私设置。',
        7: '4. 保存了旅行图片，其许可证将与Crypko的许可证保持一致。',
      },
    },
    manage: {
      title: 'Crypko管理',
      detail: '正在开发中。',
    },
    more: {
      title: '其他',
      detail: '正在开发中。',
    },
  },
  list: {
    create: '新建列表',
    creator: '列表用户',
    delete: '删除列表',
    edit: '编辑列表',
    settings: '列表设定',
    saveToList: '添加至列表',
    placeholder: '名字',
    downloadConfirmTitle: '打包下载zip文件',
    downloadConfirmText: '下载列表中自己的Crypko图片吗？（超过20个将无法下载）',
    deleteConfirmTitle: '删除列表吗？',
    deleteConfirmText: '列表中的Crypko不会删除。',
    pleaseLogin: '请登录。',
    empty: '空',
    selectFromCrypkos: '从持有选择',
    selectFromLists: '从列表(所持)选择',
    selectFromCrossfuse: '从协助融合选择',
  },
  userhome: {
    profile: '介绍',
    pickUp: '注目',
    introduction: '介绍',
  },
  page404: {
    subtitle: '访问的页面不存在。',
    desc:
      '抱歉。当前访问的页面不存在或无法显示。',
    btn: '返回上一页',
  },
  workbench: {
    'under-dev': '正在开发中。',
    select: {
      'screen-too-small': '使用此功能需要更大的屏幕尺寸。',
      'canvas-feature': '画布功能',
      'create-character': '制作角色',
      'create-illust': '制作素材',
    },
    generate: {
      'select-model': '选择生成模型',
      normalizePose: '使用标准姿势生成',
      normalizedPose: '标准姿势',
    },
    fuse: {
      'select': '选择原Crypko',
      'fuseResult': '融合结果',
      sameCrypko: '无法融合同一个Crypko。',
      notSameModel: '请选择相同模型的Crypko。',
      notCorrectModel: '存在无法进行融合的Crypko。',
      bothOthers: '请选择至少一个您持有的Crypko。',
    },
    editMode: {
      'selection': '选择工具',
      'rectangleCutout': '矩形选框工具',
      'freeCutout': '自由选框工具',
      'drawing': '画笔工具',
      'eraser': '橡皮擦工具',
      'colorBucket': '油漆桶工具',
      'undo': '取消',
      'redo': '重做',
    },
    keypoint: {
      'select': '元のCrypkoを選択',
      'load': '参照するCrypkoを選択',
      'toggle': 'ポイント表示',
      'choose-part': '部位を選択',
    },
    variation: {
      pose: '姿势',
      hand: '手',
      hair: '头发',
      face: '脸部',
      clothes: '服装',
      strength: '强度',
      scale: '范围',
      mode: '模式',
      result: '派生结果',
    },
    interpolate: {
      notCorrectModel: '请选择Freesia或FreesiaM模型的Crypko。',
      sameCrypko: '无法对同一个Crypko制作动画。',
      notSameModel: '请选择相同模型的Crypko。',
      'remove-buffer': '去除缓冲帧',
      'tweet-confirm': '请在推特中添加已下载的mp4文件。',
    },
  },
  attrs: {
    erica: {
      'HairColors': '发色',
      'EyeColors': '瞳色',
      'Others': '其他属性',
      'blonde_hair': '金发',
      'brown_hair': '茶发',
      'black_hair': '黑发',
      'blue_hair': '蓝发',
      'pink_hair': '粉发',
      'purple_hair': '紫发',
      'green_hair': '绿发',
      'red_hair': '红发',
      'silver_hair': '银发',
      'white_hair': '白发',
      'orange_hair': '橘发',
      'aqua_hair': '碧发',
      'grey_hair': '灰发',
      'blue_eyes': '蓝瞳',
      'red_eyes': '红瞳',
      'brown_eyes': '棕瞳',
      'green_eyes': '绿瞳',
      'purple_eyes': '紫瞳',
      'yellow_eyes': '黄瞳',
      'pink_eyes': '粉瞳',
      'aqua_eyes': '碧瞳',
      'black_eyes': '黑瞳',
      'orange_eyes': '橙瞳',
      'long_hair': '长发',
      'short_hair': '短发',
      'twintails': '双马尾',
      'twin_tail': '双马尾',
      'drill_hair': '螺旋发型',
      'ponytail': '马尾',
      'dark_skin': '褐色皮肤',
      'blush': '脸红',
      'smile': '笑容',
      'open_mouth': '开口',
      'hat': '帽子',
      'ribbon': '发带',
      'glasses': '眼镜',
      'animal_ears': '兽耳',
    },
    freesia: {
      'Attributes': '属性',
      'Expressions': '表情',
      'HairStyles': '发型',
      'HairColors': '发色',
      'EyeColors': '瞳色',
      'Dev': 'Dev',
      ':d': ':D',
      '^_^': '^ ^',
      ';)': ';)',
      ';d': ';D',
      ':<': ':<',
      ':o': ':o',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': '呆毛',
      'angry': '气愤',
      'animal_ears': '兽耳',
      'aqua_eyes': '碧瞳',
      'aqua_hair': '碧发',
      'bare_shoulders': '露肩',
      'black_dress': '黑色衣服',
      'black_eyes': '黑瞳',
      'black_hair': '黒髪',
      'blazer': '夹克',
      'blonde_hair': '金发',
      'blue_dress': '蓝色衣服',
      'blue_eyes': '蓝瞳',
      'blue_hair': '青发',
      'blush': '脸红',
      'bob_cut': '波波发型',
      'breasts': '胸',
      'brown_eyes': '褐瞳',
      'brown_hair': '褐发',
      'casual': '休闲服',
      'chibi': '矮个子',
      'child': '小孩',
      'closed_eyes': '闭眼',
      'closed_mouth': '闭嘴',
      'dark_skin': '褐色皮肤',
      'drill_hair': '螺旋发型',
      'dutch_angle': '斜角镜头',
      'embarrassed': '害羞',
      'green_eyes': '绿瞳',
      'green_hair': '绿发',
      'grey_eyes': '灰瞳',
      'grey_hair': '灰发',
      'hair_over_one_eye': '遮眼刘海',
      'half-closed_eyes': '半睁眼',
      'happy': '高兴',
      'jitome': '盯看',
      'light_smile': '微笑',
      'loli': '萝莉',
      'long_hair': '长发',
      'messy_hair': '乱发',
      'naughty_face': '淘气',
      'one_eye_closed': '半睁眼',
      'open_mouth': '开口',
      'orange_eyes': '橙瞳',
      'orange_hair': '橙发',
      'parted_bangs': '中分刘海',
      'pink_eyes': '桃目',
      'pink_hair': '桃髪',
      'pointy_ears': '长耳',
      'ponytail': '马尾',
      'purple_eyes': '紫目',
      'purple_hair': '紫髪',
      'red_eyes': '赤目',
      'red_hair': '红发',
      'sharp_teeth': '尖齿',
      'short_hair': '短发',
      'short_twintails': '短双马尾',
      'silver_hair': '银发',
      'smile': '笑容',
      'surprised': '吃惊',
      'tareme': '下垂眼',
      'tsurime': '上吊眼',
      'twintails': '双马尾',
      'two_side_up': '披肩双马尾',
      'very_long_hair': '超长发',
      'wavy_hair': '波浪发',
      'white_hair': '白发',
      'white_shirt': '白衬衫',
      'yellow_eyes': '黄瞳',
      'younger': '年轻',
      'under-rim_eyewear': '眼镜',
      'side_ponytail_left': '单侧马尾（左）',
      'side_ponytail_right': '单侧马尾（右）',
      'high_ponytail': '高马尾',
      'low_ponytail': '低马尾',
      'short_ponytail': '短马尾',
      'expressionless': '无表情',
      'crying_with_eyes_open': '呜咽',
      'kimono': '和服',
      'frown': '皱眉',
      'swimsuit': '泳装',
    },
    'freesia-m': {
      'Attributes': '属性',
      'Expressions': '表情',
      'HairStyles': '发型',
      'HairColors': '发色',
      'EyeColors': '瞳色',
      'Dev': 'Dev',
      ':d': ':D',
      '^_^': '^ ^',
      ';)': ';)',
      ';d': ';D',
      ':<': ':<',
      ':o': ':o',
      '=_=': '¯\\_(ーワー)_/¯',
      'ahoge': '呆毛',
      'angry': '气愤',
      'animal_ears': '兽耳',
      'aqua_eyes': '碧瞳',
      'aqua_hair': '碧发',
      'bare_shoulders': '露肩',
      'black_dress': '黑色衣服',
      'black_eyes': '黑瞳',
      'black_hair': '黒髪',
      'blazer': '夹克',
      'blonde_hair': '金发',
      'blue_dress': '蓝色衣服',
      'blue_eyes': '蓝瞳',
      'blue_hair': '青发',
      'blush': '脸红',
      'bob_cut': '波波发型',
      'breasts': '胸',
      'brown_eyes': '褐瞳',
      'brown_hair': '褐发',
      'casual': '休闲服',
      'chibi': '矮个子',
      'child': '小孩',
      'closed_eyes': '闭眼',
      'closed_mouth': '闭嘴',
      'dark_skin': '褐色皮肤',
      'drill_hair': '螺旋发型',
      'dutch_angle': '斜角镜头',
      'embarrassed': '害羞',
      'green_eyes': '绿瞳',
      'green_hair': '绿发',
      'grey_eyes': '灰瞳',
      'grey_hair': '灰发',
      'hair_over_one_eye': '遮眼刘海',
      'half-closed_eyes': '半睁眼',
      'happy': '高兴',
      'jitome': '盯看',
      'light_smile': '微笑',
      'loli': '萝莉',
      'long_hair': '长发',
      'messy_hair': '乱发',
      'naughty_face': '淘气',
      'one_eye_closed': '半睁眼',
      'open_mouth': '开口',
      'orange_eyes': '橙瞳',
      'orange_hair': '橙发',
      'parted_bangs': '中分刘海',
      'pink_eyes': '桃目',
      'pink_hair': '桃髪',
      'pointy_ears': '长耳',
      'ponytail': 'ポニーテール',
      'purple_eyes': '紫目',
      'purple_hair': '紫髪',
      'red_eyes': '赤目',
      'red_hair': '红发',
      'sharp_teeth': '尖齿',
      'short_hair': '短发',
      'short_twintails': '短双马尾',
      'silver_hair': '银发',
      'smile': '笑容',
      'surprised': '吃惊',
      'tareme': '下垂眼',
      'tsurime': '上吊眼',
      'twintails': '双马尾',
      'two_side_up': '披肩双马尾',
      'very_long_hair': '超长发',
      'wavy_hair': '波浪发',
      'white_hair': '白发',
      'white_shirt': '白衬衫',
      'yellow_eyes': '黄瞳',
      'younger': '年轻',
      'under-rim_eyewear': '眼镜',
      'side_ponytail': '侧单马尾',
      'expressionless': '无表情',
      'crying_with_eyes_open': '呜咽',
      'kimono': '和服',
      'frown': '皱眉',
      'swimsuit': '泳装',
    },
  },
  payment: {
    catalog: {
      cp: 'CP',
      features: '功能',
      model: '模型',
    },
    unit: {
      recovery: '{0} 分/CP',
      limit: '{0} CP',
      slot: '{0} 个',
    },
    plans: {
      Freemium: '免费版会员',
      Starter: '入门版会员',
      Standard: '标准版会员',
      Premium: '高级版会员',
    },
    'slot-num': '{0}个',
    'license-num': '{0}张图像',
    plan: '订阅',
    trial: '试用',
    '7-day-trial': '7天免费试用',
    'in-trial': '试用中',
    'with-trial': '开始试用',
    'without-trial': '无试用',
    'trial-desc': '您拥有7天试用的资格！\n\n*当您使用一张已经试用过的信用卡进行重复购买，您的会员将被取消。',
    invoice: '发票',
    unsubscribed: '已解约',
    membership: '会员',
    'upgrade-plan': '升级订阅',
    monthly: '每月',
    'specified-commercial-transactions': '基于特定交易法的标记',
    'plan-features': {
      'cp-recovery': 'CP恢复',
      'cp-limit': 'CP恢复上限',
      slots: '保存数',
      gen: '生成',
      fuse: '融合',
      attrs: '属性',
      variation: '派生',
      canvas: '画布',
      video: '变身动画',
      'private-save': '私密保存',
      download: '下载无水印图像',
      commercial: '商业使用',
      chat: '聊天',
      travel: '旅行',
    },
    'ip-country-not-support-billing': '现在付款仅支持部分亚洲国家。您所在的地区可能无法付款，请理解。',
    'jpy': '¥{0} 日元',
    'jpy-excluded-tax': '不含税日元 ¥{0}',
    'current-plan': '当前订阅',
    'freemium-crypko-model': '免费版用户无法使用该Crypko的生成模型。',
    'model-plan-restriction': '您的当前会员等级无法使用该模型。请升级会员。',
    'feature-plan-unavailable': '您当前的会员等级无法使用该功能。',
    'view-plans': '查看订阅选项',
    'tax-included': '（含税）',
    'tax-excluded': '（不含税）',
    'plan-ends': '订阅结束日',
    'next-payment': '下次扣款',
    'thank-you': '谢谢惠顾。',
    'manage-subscription': '管理订阅',
    'campaign': '促销',
    'first-month-only': '首月限定',
    'onetime': '单月购买',
    'recurring': '自动续费',
    '30-days': '30天',
    'discount': '低价',
    'paid-feature': '付费会员功能',
    'plan-upgrade-msg': '确定升级当前会员吗？\n我们将根据当前会员剩余时间按比例收取费用。',
    'purchase-license': '购买商用许可',
    'purchased-license': '已购买商用许可',
    'purchased': '已购买',
    'purchase-license-msg': '请在Crypko页面购买商用许可。',
    'pay-by-email': '发票将通过电子邮件发送。请按照邮件中的步骤完成付款。',
    'pay-by-checkout': '您将被转到支付界面直接完成付款。如果您选择这个选项，我们将不会提供发票。',
    'check-email-invoice': '发票已发送至您的邮箱 {0}\n请按照邮件中的步骤完成付款。',
    'invoice-paid': '我已付款',
    'coin-exchange-msg': '确定要交换吗？',
    notice: {
      'tax-included-price': '※含税日元价格',
      'tax-excluded-price': '※不含税日元价格',
      'payment-countries': '※我们支持来自日本、美国、中国大陆、台湾、香港、澳门、韩国、澳大利亚、新加坡、新西兰以及27个欧盟国家的支付。请确保您的付款账单地址在上述国家和地区之内。',
      'country-tax-rate': '※日本以外的用户适用各国的税率。',
      'item-purchase-desc': '※不论加入的是何种会员，都可以购买所有道具。不论加入的是何种会员，都可以按张购买保存在卡槽中的Crypko图像的商用许可证。购买了商用许可证的Crypko图像，不论加入的是何种订阅，只要是保存在卡槽的期间，均可进行商用使用（私人保存、无水印图像下载除外）。详情请确认使用条款、Crypko指南以及FAQ。',
      'plan-cp-desc': '※新加入各会员时，CP余额被设定为各订阅规定的CP恢复上限值。但是，在加入标准版会员期间升级为高级版会员时，将继续保持标准版会员的CP余额。',
      'cp-recover-desc': '※当CP余额超出了各会员规定的CP恢复上限值时，CP将无法恢复。',
      'cp-6times-desc1': '※免费会员的CP保有上限，是CP恢复上限的值。标准版会员及高级版会员的CP保有上限，是各会员规定的CP恢复上限的6倍。',
      'cp-6times-desc2': '※按月定期购买的标准版会员及高级版会员会在每月续约时，将此时的CP余额转结入各订阅规定的CP恢复上限值内。但是，当CP余额超出CP的保有上限值时，不在此限。因此，CP的使用期限实际上是自附加后起6个月以内。',
      'plan-change-desc': '※按月定期购买的标准版会员及高级版会员被取消时，将在超过剩余的有效期后，自动转换到免费版会员。单次购买的标准版会员及高级版会员，会在超过有效期后，自动转换到免费版会员。此外，即使已转换到免费版会员，也可以再次按月定期购买或单次购买标准版会员及高级版会员。',
      'canvas-fuse-desc': '※使用画布功能制作的Crypko图像，不属于融合功能及属性功能的对象。',
      'more': '※详情请确认{0}、{1}及{2}。',
    },
  },
  notifications: {
    none: '暂无通知',
    title: {
      info: '通知',
      fav: '获得点赞',
      follow: '新增关注',
      fill: '获得 CP',
      slot: '获得保存数',
      crossfuse: '协助融合',
      crossfuse_result: '协助融合',
      fav_album: '获得点赞',
    },
    content: {
      fav: '{0} 赞了你的 Crypko {1}。',
      follow: '{0} 开始关注你。',
      fill: '系统赠送 {0} CP。',
      slot: '系统赠送 {0} 保存数。',
      crossfuse: '{0} 使用您的 Crypko {1} 进行了协助融合。',
      'crossfuse-reward': '获得了{0}。',
      'crossfuse_result': '查看 {0} 与您的 Crypko {1} 进行的协助融合结果： {2}。',
      fav_album: '{0} 赞了您的旅行相片 {1}。',
    },
    'read-all': '全部已读',
  },
  search: {
    'no-data': '没有找到相关 Tag，将根据名称进行搜索。',
    'search-by-hash': '根据 Hash 搜索...',
    'hint': 'Hash, 名称, 标签...',
  },
  link: {
    'privacy-policy': 'https://www.preferred.jp/en/policy/',
  },
  changelogs: {
    230614: {
      img: '/images/updates/230614/banner-cn.png',
      'vid-url': 'https://www.bilibili.com/video/BV1Vh4y1M7CZ/',
      title: 'Crypko聊天功能',
      desc: '聊天功能现已推出Alpha测试。详情请见视频。',
    },
    230712: {
      img: '/images/updates/230712/banner.jpg',
      title: 'Gerbera(M)姿势派生功能',
      desc: 'Gerbera(M)模型的Crypko现已推出姿势派生功能。\n*付费会员功能。',
    },
    230720: {
      title: '【已结束】泳装属性',
      desc: 'Freesia(M)的泳装属性调整现已推出。该属性至八月底期间限定可用。让你喜欢的Crypko穿上泳装吧！\n※属性为付费功能',
    },
    230810: {
      img: '/images/updates/230810/banner-cn.jpg',
      title: '【已结束】看板娘（泳装）兑换活动',
      desc: '上述限定日期内看板娘（泳装）可以用累计1000CP进行兑换。此活动适用于所有用户。快让看板娘穿上泳装并与她聊天吧！\n',
    },
    230930: {
      img: '/images/updates/230930/banner.jpg',
      title: '新属性',
      desc: '属性功能中新增可调整属性： ¯\\_(ーワー)_/¯ ！\n*付费会员功能',
    },
    231020: {
      img: '/images/updates/231020/banner.jpg',
      title: '排行榜',
      desc: 'Gallery中现已添加用户排行榜🔥',
    },
    231027: {
      img: '/images/updates/231027/banner.jpg',
      title: '标签稀有度',
      desc: 'Freesia模型的生成结果的标签稀有度现已直接显示。\n*我们对标签稀有度的计算方法进行了调整。',
    },
    240116: {
      img: '/images/updates/240116/banner-cn.jpg',
      title: '每日任务与新代币',
      desc: '新增了{0}星币，可以用来兑换CP或者保存数。完成每日任务，赚取{0}星币吧！',
    },
    240321: {
      img: '/images/help/travel.jpg',
      title: 'Crypko旅行',
      desc: '全新旅行功能现在开放Beta测试！前往不同的世界，收集角色的旅行相片吧！',
    },
    240520: {
      img: '/images/updates/240520/orbis.jpg',
      title: '新旅行世界',
      desc: '新旅行世界Orbis现已推出！出发前往与现实世界相似的平行世界吧！',
    },
    240525: {
      img: '/images/updates/240525/leg.jpg',
      img2: '/images/updates/240525/gerbera.jpg',
      title: '旅行相片生成修正',
      desc: '修复旅行相片生成的相关问题已修正。\n1. 角色的腿部经常埋在水或土中的问题。',
      desc2: '2. Gerbera模型的旅行相片崩坏问题。',
    },
    240708: {
      img: '/images/updates/240708/banner.jpg',
      title: '旅行相片的角色台词',
      desc: '旅行功能新增了以一定概率生成带有角色台词的旅行照片的功能！\n*付费用户可以控制此功能。',
    },
    240726: {
      title: '新旅行世界',
      desc: '新旅行世界Cybritus现已推出！前往探索由赛博科技主导的未来都市世界！\n*付费功能',
    },
    240823: {
      title: '新模型Hibiscus',
      desc: '新增了新的生成模型Hibiscus！使用新模型生成更多Crypko吧！',
    },
    241021: {
      title: '【结束】万圣节限定旅行世界🎃',
      desc: '新旅行世界Halloween现已推出！在充满诡异氛围的南瓜灯火摇曳的黑夜世界中旅行吧！',
    },
    241213: {
      title: '【结束】圣诞限定旅行世界🎄',
      desc: '圣诞期间限定的新旅行世界Christmas现已推出！前往雪花飘落、闪烁的灯光和圣诞树的幻想世界吧！',
    },
    250320: {
      title: '旅行功能更新',
      desc: '1. 限时新增旅行世界『Sakura』！畅享樱花飘落的春日世界吧！\n2. 旅行的CP消耗从30CP调整为20CP。\n3. 新增付费计划专属的高分辨率模式！高分辨率模式下的CP消耗为普通的1.5倍。',
    },
    250331: {
      title: '关于 Crypko 服务终止的通知',
    },
  },
  rank: {
    fav_rank_week: '点赞（周）',
    crossfuse_rank_week: '协助融合（周）',
    fav_rank_all: '点赞（总）',
    follower_rank: '关注',
  },
  dailyTask: {
    title: '每日任务',
    action: {
      fav: '发送{0}次点赞',
      gen: '进行{0}次生成',
      fuse: '进行{0}次融合',
      save: '进行{0}次保存',
      crossfuse_set: '在协助融合中设置Crypko',
      travel: '进行{0}次旅行',
    },
  },
  play: {
    travel: {
      title: '旅行',
      desc: '✈️ 前往不同的世界，收集旅行相片。',
    },
    mascot: {
      title: '看板娘',
      desc: '💬 与Crypko看板娘互动聊天。',
    },
  },
  introduction: {
    travel: {
      desc: '将只属于您的角色送去不同世界旅行，收集独一无二的旅行相片吧！',
      contact: '*任意角色图片的背景生成可针对商业用户提供，详情请联系我们。',
    },
    'release': {
      'title': 'Crypko 正式发布',
      'start': '动漫角色生成平台 Crypko™',
      'start-2': 'v1.0 正式版',
      'notice-on-terms': '※Crypko具有编辑功能，可以对生成的角色进行编辑。请用户在使用时务必遵守{0}。',
      'terms-link-text': '服务条款',
      'signup': '开始制作动漫角色',
    },
    'generation': {
      'title': '角色生成',
      'detail': '利用 GAN (生成对抗网络) 技术，Crypko™ 能够自动生成高质量的动漫立绘。',
      'text': '通过学习图像的特征，模型可以在万千立绘的无尽空间中自由而连贯地进行变换。',
    },
    'memes': {
      'title': '手机APP MEMES',
      'detail': 'Crypko作为核心科技搭载于手机APP的MEMES，现可在Apple App Store与Google Play Store免费下载。',
      'access': 'MEMES 官网',
    },
    'chara-design': {
      'title': '角色设计',
      'detail': '高质量图像的生成不是深度学习的黑箱，任何人都可以参与其中。',
      'detail2': '借此，你所思所想，将具象化为栩栩如生的角色。',
    },
    'animation': {
      'title': '更多创意的可能性',
      'detail': '不需要绘画基础，用户即可修改角色的设计，添加自然的动画。',
    },
  },
  travel: {
    'crypko-travel': 'Crypko旅行',
    actions: '行动',
    photos: '旅行相片',
    'go-travel': '前去旅行',
    'obtain-photos': '在旅行中获得相片吧',
    'claim-with-save': '确认并保存至Crypko',
    'save-album': '保存至Crypko',
    saved: '已保存',
    worlds: '世界',
    'worlds-info': {
      Arcadia: '自然丰富的幻想世界',
      Orbis: '与现实世界相似的平行世界',
      Cybritus: '由赛博科技主导的未来都市世界',
      Halloween: '弥漫着诡异氛围，南瓜灯火摇曳的黑夜世界',
      Christmas: '雪花飘落，闪烁的灯光和圣诞树，充满幻想的世界',
      Sakura: '樱花飘落的春天世界',
    },
    history: '历史记履歴录',
    'history-display': '仅显示一个月的历史记录',
    state: {
      traveling: '旅行中',
      generating: '旅行中',
      unclaimed: '确认结果',
      failed: '失败',
    },
    'generating-msg': '旅行相片正在生成。通常情况下，这需要不到20秒。',
    'failed-msg': '旅行相片生成失败。将返还使用的 {0} CP',
    'has-travel-msg': '在当前旅行的结果确认后，才能开始新的旅行',
    'start-travel-msg': '选择世界后可以开始旅行。',
    'please-select-crypko': '请选择Crypko',
    duration: '旅行时间',
    'fast-travel': '时间缩短',
    'fast-travel-notice': '* 请注意，旅游照片生成根据服务器负载大约需要20秒。',
    'first-travel-notice': '* 第一次旅行时自动免费设定为最大时间短缩。',
    'view-album-crypko': '在Crypko中查看',
    'remove-album': '从Crypko移除',
    'remove-msg': '删除旅行消息',
    'insufficient-album-slot': '相片槽不足',
    'coin-exchange-album-msg': '消耗{0}星币来兑换1个相片槽？',
    'start-now': '立刻出发',
    'welcome-desc': '生成角色插图',
    'gen-msg': '角色台词',
    'gen-msg-desc': '即使是免费会员，也可以在一定概率下生成角色台词。但是升级到付费会员，您可以自由使用角色台词生成功能。',
    'paid-world': '升级到付费会员，解锁旅行世界。',
    'high-res': '高分辨率',
  },
  survey: {
    'submit-error': '提交失败。您可能已经提交过了。',
    'help-us-improve': '帮助我们改进Crypko！',
    'complete-msg': '500星币已发放！您可以在商店中兑换CP或保存数。',
  },
}
