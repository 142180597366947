import { render, staticRenderFns } from "./Update250320.vue?vue&type=template&id=71931e35&scoped=true&"
import script from "./Update250320.vue?vue&type=script&lang=js&"
export * from "./Update250320.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71931e35",
  null
  
)

export default component.exports